import React from 'react';
import GeneralInput from '../../GeneralInput';
import SectionHeadline from '../ui/sectionHeadline';

const Teeth = ({ data, updateData }) => {
  const handleInputChange = (id, key, value) => {
    updateData(id, key, value);
  };

  return (
    <div className="p-4">
      <SectionHeadline sectionName="pzr" />
      <GeneralInput
        id="pzr_1"
        type="conditional-radio-two"
        label="Zuschuss durch Satzungsleistung"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.pzr_1?.value || ''}
        onChange={(value) => handleInputChange('pzr_1', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.pzr_1?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('pzr_1', 'valueOption1', value),
          },
          text2: {
            value: data?.pzr_1?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('pzr_1', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="pzr_2"
        type="conditional-radio-two"
        label="Zuschuss durch Gesundheitskonto / Punkteprogramm"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.pzr_2?.value || ''}
        onChange={(value) => handleInputChange('pzr_2', 'value', value)}
        conditionalValues={{
          text1: {
            value: data?.pzr_2?.valueOption1 || '',
            label: 'Wenn ja, wie hoch?',
            placeholder: 'Höhe in € eingeben',
            onChange: (value) => handleInputChange('pzr_2', 'valueOption1', value),
          },
          text2: {
            value: data?.pzr_2?.valueOption2 || '',
            label: 'Wenn ja, wie oft?',
            placeholder: 'Häufigkeit in Monaten eingeben',
            onChange: (value) => handleInputChange('pzr_2', 'valueOption2', value),
          },
        }}
      />
      <GeneralInput
        id="pzr_3"
        type="select"
        label="Vollständige Übernahme im DentNet"
        options={[
          { label: '0* p.a.', value: '0' },
          { label: '1* p.a.', value: '1' },
          { label: '2* p.a.', value: '2' },
        ]}
        value={data?.pzr_3?.value || '0'}
        onChange={(value) => handleInputChange('pzr_3', 'value', value)}
      />

      <SectionHeadline sectionName="dentures" />
      <GeneralInput
        id="dentures_1"
        type="select"
        label="Vergünstigter Zahnersatz"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.dentures_1?.value || 'no'}
        onChange={(value) => handleInputChange('dentures_1', 'value', value)}
      />

      <SectionHeadline sectionName="toothcorrection" />
      <GeneralInput
        id="toothCorrection_1"
        type="select"
        label="Vergünstigte Zahnkorrektur"
        options={[
          { label: 'Ja', value: 'yes' },
          { label: 'Nein', value: 'no' },
        ]}
        value={data?.toothCorrection_1?.value || 'no'}
        onChange={(value) => handleInputChange('toothCorrection_1', 'value', value)}
      />
    </div>
  );
};

export default Teeth;
