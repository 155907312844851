import React, { useState, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Navbar from "../Layout/Navbar";
import Spinner from "../../components/Spinner";
import dentnet from "../../components/assets/dentnet.png";
import laugh from "../../components/assets/laugh.webp";
import { UserContext } from "../../context/UserContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const Login = () => {
  const { login, isLoading } = useContext(UserContext);
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false); // State for password visibility

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(formData);
    } catch (error) {
      toast.error("Login failed: " + (error.response?.data?.message || error.message));
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <>
      <div className="min-w-screen min-h-screen bg-white flex items-center justify-center px-5 py-5">
        <ToastContainer />
        {isLoading && <Spinner />}
        <Navbar />
        <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-2xl w-full overflow-hidden" style={{ maxWidth: "1000px" }}>
          <div className="md:flex w-full">
            <div className="hidden md:block w-1/2 bg-slate-150 shadow-2xl relative overflow-hidden">
              <img
                src={laugh}
                alt="Laugh"
                className="absolute inset-0 w-full h-full object-cover object-center"
              />
            </div>
            <div className="w-full md:w-1/2 py-10 px-5 md:px-10">
              <div className="text-center mb-10">
                <img src={dentnet} alt="Dentnet Logo" className="mx-auto" />
                <h2 className="font-bold text-3xl text-gray-900">Willkommen</h2>
              </div>
              <form onSubmit={handleSubmit}>
                <div className="mb-5">
                  <input
                    type="email"
                    name="email"
                    className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="mb-5 relative">
                  <input
                    type={showPassword ? "text" : "password"} 
                    name="password"
                    className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                    placeholder="Passwort"
                    value={formData.password}
                    onChange={handleChange}
                    required
                  />
                  <span
                    onClick={togglePasswordVisibility} 
                    className="absolute right-4 top-5 cursor-pointer"
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
                <div className="flex justify-between items-center mb-5">
                  <a
                    href="/forgot-password"
                    className="text-indigo-500 hover:text-indigo-700 text-sm"
                  >
                    Passwort vergessen?
                  </a>

                  <button
                    type="submit"
                    className="bg-[#0098D6] hover:bg-[#0098D6] focus:bg-[#0098D6] text-white rounded-lg px-4 py-2 font-semibold float-right"
                    disabled={isLoading}
                  >
                    {isLoading ? "Logging in..." : "LOGIN"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
