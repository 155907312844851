import React, { useState, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserContext } from "../context/UserContext";
import axios from "axios";

const ChangePassword = () => {
  const { user, isLoading, setIsLoading } = useContext(UserContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    if (!user || !user.userId) {
      toast.error("User not found or not logged in");
      console.log("User data not available:", user);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/update-info`, {
        userId: user.userId,
        newEmail: email,
      });

      console.log("Email Update Response:", response);
      console.log("Email Response:", response.status);
      toast.success(response.data.message);  
      if (response.status === 200) {
        toast.success("Email updated successfully.");
        setEmail(""); 
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to update email: " + (error.response?.data?.message || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    if (!user || !user.userId) {
      toast.error("User not found or not logged in");
      console.log("User data not available:", user);
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_BASE_URL}/update-info`, {
        userId: user.userId,
        newPassword: password,
      });

      console.log("Password Update Response:", response);

      if (response.status === 200) {
        console.log("hemlo!");
        toast.success("Password updated successfully.");
        setPassword(""); 
      }
    } catch (error) {
      console.error("API Error:", error);
      toast.error("Failed to update password: " + (error.response?.data?.message || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="min-w-screen min-h-screen bg-white flex items-center justify-center px-5 py-5">
        <ToastContainer />
        <div className="bg-gray-100 text-gray-500 rounded-3xl shadow-2xl w-full overflow-hidden" style={{ maxWidth: "1000px" }}>
          <div className="text-center mt-6 mr-5">
            <h2 className="font-bold text-3xl text-gray-900">Zugangsdaten ändern</h2>
          </div>

          {/* Email Update Form */}
          <div className="flex justify-center w-full py-5 mt-8"> 
            <form onSubmit={handleEmailSubmit} className="w-full md:w-1/2">
              <div className="mb-5 flex items-center">
                <input
                  type="email"
                  name="email"
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="Neue Emailadresse..."
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
                <button
                  type="submit"
                  className="bg-[#0098D6] hover:bg-[#0098D6] focus:bg-[#0098D6] text-white rounded-lg px-4 py-2 font-semibold ml-3"
                  disabled={isLoading}
                >
                  {isLoading ? "Gesendet..." : "Senden"}
                </button>
              </div>
            </form>
          </div>

          {/* Password Update Form */}
          <div className="flex justify-center w-full py-5"> 
            <form onSubmit={handlePasswordSubmit} className="w-full md:w-1/2">
              <div className="mb-5 flex items-center">
                <input
                  type="password"
                  name="password"
                  className="w-full px-4 py-3 rounded-lg border-2 border-gray-200 outline-none focus:border-indigo-500"
                  placeholder="Neues Passwort..."
                  value={password}
                  onChange={handlePasswordChange}
                  required
                />
                <button
                  type="submit"
                  className="bg-[#0098D6] hover:bg-[#0098D6] focus:bg-[#0098D6] text-white rounded-lg px-4 py-2 font-semibold ml-3"
                  disabled={isLoading}
                >
                  {isLoading ? "Gesendet..." : "Senden"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
