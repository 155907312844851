import React, { useState, useEffect, useRef } from 'react';
import { FaUpload } from 'react-icons/fa';
import ReactSelect from 'react-select';

const GeneralInput = ({
  id,
  type,
  label,
  options,
  placeholder,
  onChange,
  value,
  preview,
  conditionalValues
}) => {
  const [selectedValue, setSelectedValue] = useState(value);
  const [selectedFile, setSelectedFile] = useState(null);
  const [conditionalValue1, setConditionalValue1] = useState(conditionalValues?.text1?.value || '');
  const [conditionalValue2, setConditionalValue2] = useState(conditionalValues?.text2?.value || '');
  const fileInputRef = useRef(null);

  useEffect(() => {
    setSelectedValue(value);
    if (type === 'file' && value) {
      setSelectedFile(value);
    }
  }, [type, value]);

  const handleBaseChange = (newValue) => {
    setSelectedValue(newValue);
    onChange(newValue);
  };

  const handleChange = (selected) => {
    if (type === 'multi-select' && Array.isArray(selected)) {
      handleBaseChange(selected.map(s => s.value));
    } else if (type === 'file') {
      const file = selected.target.files[0];
      setSelectedFile(file);
      handleBaseChange(file);
    } else {
      handleBaseChange(selected.target ? selected.target.value : selected);
    }
  };

  const handleConditionalChange1 = (e) => {
    setConditionalValue1(e.target.value);
    if (conditionalValues?.text1?.onChange) {
      conditionalValues.text1.onChange(e.target.value);
    }
  };

  const handleConditionalChange2 = (e) => {
    setConditionalValue2(e.target.value);
    if (conditionalValues?.text2?.onChange) {
      conditionalValues.text2.onChange(e.target.value);
    }
  };

  const handleFileButtonClick = (e) => {
    e.preventDefault(); 
    fileInputRef.current && fileInputRef.current.click();
  };

  return (
    <div className="p-4 space-y-4">
      <label htmlFor={id} className="block font-medium text-gray-700">{label}</label>
      {type === 'select' && (
        <select
          id={id}
          className="block w-full border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300 h-10 pl-2"
          value={selectedValue}
          onChange={handleChange}
        >
          {options.map(option => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select>
      )}
      {type === 'multi-select' && (
        <ReactSelect
          id={id}
          isMulti
          options={options}
          value={options.filter(option => selectedValue.includes(option.value))}
          onChange={handleChange}
          placeholder={placeholder}
        />
      )}
      {type === 'textarea' && (
        <textarea
          id={id}
          className="block w-full border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300"
          rows="4"
          placeholder={placeholder}
          value={selectedValue}
          onChange={handleChange}
        />
      )}
     {type === 'file' && (
        <>
          <button
            className="w-full px-4 py-2 font-bold text-white bg-green-500 rounded-full hover:bg-blue-700 focus:outline-none focus:shadow-outline"
            onClick={handleFileButtonClick}
          >
            <FaUpload className="inline mr-2" />
            Datei hochladen
          </button>
          <input
            type="file"
            id={id}
            ref={fileInputRef}
            className="hidden"
            onChange={handleChange}
            accept="image/*"
          />
          {(selectedFile || preview) && (
            <div className="mt-2">
              <img 
                src={selectedFile ? URL.createObjectURL(selectedFile) : preview} 
                alt="Logo Preview" 
                className="max-w-xs max-h-32 object-contain"
              />
            </div>
          )}
        </>
      )}
      {type === 'text' && (
        <input
          id={id}
          type="text"
          className="block w-full border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300 h-10 pl-2"
          placeholder={placeholder}
          value={selectedValue}
          onChange={handleChange}
        />
      )}
      {type === 'radio' && (
        <>
          <div className="flex space-x-4">
            {options.map(option => (
              <label key={option.value} className={`inline-flex items-center rounded-md p-2 border ${selectedValue === option.value ? 'border-blue-300 bg-blue-100' : ''}`}>
                <input
                  type="radio"
                  className="form-radio text-blue-500 focus:ring focus:ring-blue-300"
                  value={option.value}
                  checked={selectedValue === option.value}
                  onChange={handleChange}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            ))}
          </div>
        </>
      )}
      {type === 'conditional-radio-one' && (
        <>
          <div className="flex space-x-4">
            {options.map(option => (
              <label key={option.value} className={`inline-flex items-center rounded-md p-2 border ${selectedValue === option.value ? 'border-blue-300 bg-blue-100' : ''}`}>
                <input
                  type="radio"
                  className="form-radio text-blue-500 focus:ring focus:ring-blue-300"
                  value={option.value}
                  checked={selectedValue === option.value}
                  onChange={handleChange}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            ))}
          </div>
          {selectedValue === 'yes' && (
            <>
              <label className="block font-medium text-gray-700">{conditionalValues?.text1?.label || "If Yes, how much?"}</label>
              <input
                type="text"
                className="block w-full border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300 h-10 pl-2"
                placeholder={conditionalValues?.text1?.placeholder || "Enter subsidy amount"}
                value={conditionalValue1}
                onChange={handleConditionalChange1}
              />
            </>
          )}
        </>
      )}
      {type === 'conditional-radio-two' && (
        <>
          <div className="flex space-x-4">
            {options.map(option => (
              <label key={option.value} className={`inline-flex items-center rounded-md p-2 border ${selectedValue === option.value ? 'border-blue-300 bg-blue-100' : ''}`}>
                <input
                  type="radio"
                  className="form-radio text-blue-500 focus:ring focus:ring-blue-300"
                  value={option.value}
                  checked={selectedValue === option.value}
                  onChange={handleChange}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            ))}
          </div>
          {selectedValue === 'yes' && (
            <>
              <label className="block font-medium text-gray-700">{conditionalValues?.text1?.label || "If Yes, how much?"}</label>
              <input
                type="text"
                className="block w-full border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300 h-10 pl-2"
                placeholder={conditionalValues?.text1?.placeholder || "Enter subsidy amount"}
                value={conditionalValue1}
                onChange={handleConditionalChange1}
              />
              <label className="block font-medium text-gray-700">{conditionalValues?.text2?.label || "If Yes, how often?"}</label>
              <input
                type="text"
                className="block w-full border rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-300 h-10 pl-2"
                placeholder={conditionalValues?.text2?.placeholder || "Enter frequency"}
                value={conditionalValue2}
                onChange={handleConditionalChange2}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default GeneralInput;
