import { PDFDocument, rgb } from "pdf-lib";

/**
 * this function takes in an application object and creates a downloadable pdf out of it
 *
 *
 *
 *
 */

export const handlePrint = async (selectedApplication) => {
  if (!selectedApplication) return;

  const pdfDoc = await PDFDocument.create();
  const [width, height] = [700, 300];
  const pages = [
    pdfDoc.addPage([width, height]),
    pdfDoc.addPage([width, height]),
    pdfDoc.addPage([width, height]),
    pdfDoc.addPage([width, height]),
    pdfDoc.addPage([width, height]),
  ];

  const helveticaFont = await pdfDoc.embedFont("Helvetica");
  const colors = {
    text: rgb(0, 0, 0),
    seaBlue: rgb(0, 105 / 255, 148 / 255),
    green: rgb(0, 0.5, 0),
    gray: rgb(0.5, 0.5, 0.5),
  };
  const border = {
    x: 25,
    y: 25,
    width: width - 50,
    height: height - 50,
    borderColor: colors.gray,
    borderWidth: 1,
  };

  const drawRectangle = (page) => {
    page.drawRectangle(border);
  };

  const drawText = (
    page,
    text,
    x,
    y,
    size,
    align = "left",
    color = colors.text
  ) => {
    const textWidth = helveticaFont.widthOfTextAtSize(text, size);
    const xPos =
      align === "center"
        ? (width - textWidth) / 2
        : align === "right"
        ? width - textWidth - x
        : x;
    page.drawText(text, { x: xPos, y, size, font: helveticaFont, color });
  };

  const renderSection = (page, title, fields, startY) => {
    drawText(page, title?.toString(), 50, startY, 18, "left", colors.seaBlue);
    let y = startY - 30;
    fields.forEach(({ label, value }) => {
      drawText(page, label?.toString(), 50, y, 12, "left", colors.text);
      drawText(
        page,
        value?.toString() || "-",
        240,
        y,
        12,
        "left",
        colors.green
      );
      y -= 20;
    });
  };

  // Draw borders for all pages
  pages.forEach(drawRectangle);

  // Section data
  const sections = [
    {
      page: pages[0],
      title: "Ihre Kontaktdaten",
      fields: [
        {
          label: "Name:",
          value: `${selectedApplication.salutation} ${selectedApplication.firstname} ${selectedApplication.lastname}`,
        },
        { label: "Email:", value: selectedApplication.email },
        {
          label: "Postleitzahl, Ort:",
          value: `${selectedApplication.zip} ${selectedApplication.city}`,
        },
        {
          label: "Straße, Hausnummer:",
          value: `${selectedApplication.street} ${selectedApplication.housenumber}`,
        },
        //transform date format to better looking format
        {
          label: "Geburtsdatum",
          value: selectedApplication.date_of_birth
            .toString()
            .split("T")[0]
            .split("-")
            .reverse()
            .join("."),
        },
        { label: "Telefonnummer", value: selectedApplication.phone },
        { label: "Krankenkasse:", value: selectedApplication.health_insurance },
      ],
    },
    {
      page: pages[1],
      title: "Aktuelle Krankenkasse",
      fields: [
        { label: "Name:", value: selectedApplication.current_health_insurance },
        {
          label: "Versicherungsart:",
          value: selectedApplication.current_insurance_type || "Gesetzlich versichert",
        },
        {
          label: "Versicherungs Situation:",
          value: selectedApplication.insurance_situation,
        },
        {
          label: "Minimale Versicherungszeit erfüllt:",
          value: booleanToGerman(selectedApplication.min_insurance_time),
        },
      ],
    },
    {
      page: pages[2],
      title: "Zusätzliche Informationen",
      fields: [
        { label: "Kinder:", value: selectedApplication.children },
        { label: "Ehepartner:", value: selectedApplication.insure_spouse },
        {
          label: "Einkommen Selbstständig:",
          value: selectedApplication.selfemployed_income,
        },
        { label: "Pension Status:", value: selectedApplication.has_pension },
        {
          label: "Versicherungsstatus:",
          value: selectedApplication.freed_from_health_insurance,
        },
      ],
    },
    {
      page: pages[3],
      title: "Arbeitgeber Info",
      fields: [
        { label: "Einkommen:", value: selectedApplication.income },
        { label: "Firma:", value: selectedApplication.company_name },
        { label: "Postleitzahl:", value: selectedApplication.zip },
        { label: "Straße:", value: selectedApplication.street },
        { label: "Hausnummer:", value: selectedApplication.housenumber },
        {
          label: "Arbeitgeberwechsel:",
          value: booleanToGerman(selectedApplication.change_of_employement),
        },
        {
          label: "Anstellungsdauer:",
          value: booleanToGerman(selectedApplication.min_employement_duration),
        },
        {
          label: "Verhältnis zum Arbeitgeber:",
          value: booleanToGerman(selectedApplication.related_to_employer),
        },
        {
          label: "Unternehmensanteile:",
          value: booleanToGerman(selectedApplication.holding_company_shares),
        },
        {
          label: "Mehrere Arbeitgeber:",
          value: booleanToGerman(selectedApplication.multiple_employers),
        },
      ],
    },
    {
      page: pages[4],
      title: "Geburtsinformationen",
      fields: [
        {
          label: "Geburtsname:",
          value: `${selectedApplication.salutation} ${selectedApplication.firstname} ${selectedApplication.lastname}`,
        },
        { label: "Geburtsort:", value: selectedApplication.place_of_birth },
        { label: "Geburtsland:", value: selectedApplication.birth_country },
        { label: "Nationalität:", value: selectedApplication.nationality },
      ],
    },
  ];

  // Render all sections
  sections.forEach(({ page, title, fields }) =>
    renderSection(page, title, fields, 250)
  );

  // Save and trigger download
  const pdfBytes = await pdfDoc.save();
  const blob = new Blob([pdfBytes], { type: "application/pdf" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${selectedApplication.firstname}_${selectedApplication.lastname}_Zusammenfassung.pdf`;
  link.click();
  window.open(link.href, "_blank");
};

function booleanToGerman(value) {
  return value ? "Ja" : "Nein";
}